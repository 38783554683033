import { StorageConfig } from './types';
import { storageConfig } from './config';
import imageCompression from 'browser-image-compression';

const MAX_BASE64_SIZE = 750 * 1024; // 750KB (allowing for ~1MB Firestore document limit)

export class Base64Error extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Base64Error';
  }
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(new Base64Error('Failed to convert file to base64: ' + error));
  });
};

export const validateBase64Size = (base64String: string): void => {
  const size = new Blob([base64String]).size;
  if (size > MAX_BASE64_SIZE) {
    throw new Base64Error(`Base64 string exceeds maximum size of ${MAX_BASE64_SIZE / 1024}KB`);
  }
};

export const compressImage = async (
  file: File,
  config: StorageConfig = storageConfig
): Promise<File> => {
  console.log('Starting compression for:', { name: file.name, size: file.size });

  const options = {
    maxSizeMB: config.maxSizeMB,
    maxWidthOrHeight: Math.max(config.maxWidth, config.maxHeight),
    useWebWorker: true,
    quality: config.compressionQuality,
    fileType: file.type
  };

  try {
    const compressedFile = await imageCompression(file, options);
    console.log('Compression complete:', {
      originalSize: file.size,
      compressedSize: compressedFile.size,
      ratio: `${((compressedFile.size / file.size) * 100).toFixed(1)}%`
    });
    return compressedFile;
  } catch (error) {
    console.error('Compression failed:', error);
    throw new Base64Error('Image compression failed: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
};

export const processImageForStorage = async (
  file: File,
  config: StorageConfig = storageConfig
): Promise<string> => {
  try {
    console.log('Processing image:', { name: file.name, type: file.type, size: file.size });

    // Validate file type
    if (!config.allowedTypes.includes(file.type)) {
      throw new Base64Error(`Invalid file type. Allowed types: ${config.allowedExtensions.join(', ')}`);
    }

    // First compression attempt
    let processedFile = await compressImage(file, config);
    let base64String = await fileToBase64(processedFile);
    
    // If still too large, compress again with lower quality
    if (new Blob([base64String]).size > MAX_BASE64_SIZE) {
      console.log('First compression insufficient, trying again with lower quality');
      const lowerQualityConfig = {
        ...config,
        compressionQuality: config.compressionQuality * 0.8,
        maxWidth: Math.floor(config.maxWidth * 0.8),
        maxHeight: Math.floor(config.maxHeight * 0.8)
      };
      
      processedFile = await compressImage(processedFile, lowerQualityConfig);
      base64String = await fileToBase64(processedFile);
    }

    // Final size validation
    validateBase64Size(base64String);
    
    console.log('Image processed successfully:', {
      originalSize: file.size,
      finalSize: new Blob([base64String]).size,
      ratio: `${((new Blob([base64String]).size / file.size) * 100).toFixed(1)}%`
    });

    return base64String;
  } catch (error) {
    console.error('Failed to process image:', error);
    throw error instanceof Base64Error ? error : new Base64Error('Failed to process image');
  }
};