import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { ArrowLeft, Save, Plus } from 'lucide-react';
import AdminNav from '../components/AdminNav';
import ProjectForm from '../components/ProjectForm';
import BlogForm from '../components/BlogForm';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import AiFormGenerator from '../components/AiFormGenerator';
import { useProjects } from '../hooks/useProjects';
import { usePosts } from '../hooks/usePosts';
import { projectValidationSchema, blogValidationSchema } from '../utils/formValidation';
import { processFormData, formatFormData } from '../utils/formProcessing';
import { generateProjectContent, generateBlogContent, enhanceText } from '../services/aiService';
import type { ProjectForm as ProjectFormType, BlogForm as BlogFormType } from '../types/forms';

const AdminFormPage: React.FC = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const navigate = useNavigate();
  const isProject = type === 'project' || type === 'projects';
  const isEditing = Boolean(id);

  const [initializing, setInitializing] = useState(true);
  const [enhancing, setEnhancing] = useState<string | null>(null);
  const [mainImage, setMainImage] = useState<string[]>([]);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [authorAvatar, setAuthorAvatar] = useState<string[]>([]);

  const { projects, loading: projectsLoading, error: projectsError, createProject, updateProject } = useProjects();
  const { posts, loading: postsLoading, error: postsError, createPost, updatePost } = usePosts();

  const item = isProject
    ? projects.find(p => p.id === id)
    : posts.find(p => p.id === id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    resolver: zodResolver(isProject ? projectValidationSchema : blogValidationSchema),
    defaultValues: isProject ? {
      gallery: [],
      challenges: [],
      solutions: [],
      links: {
        demo: '',
        github: ''
      }
    } : undefined
  });

  useEffect(() => {
    if (isEditing && item) {
      const formattedData = formatFormData(item, isProject ? 'project' : 'blog');
      reset(formattedData);

      if (isProject) {
        setMainImage(item.image ? [item.image] : []);
        setGalleryImages(item.gallery || []);
      } else {
        setMainImage(item.image ? [item.image] : []);
        setAuthorAvatar(item.author?.avatar ? [item.author.avatar] : []);
      }
    }
    setInitializing(false);
  }, [item, reset, isProject, isEditing]);

  const handleGenerate = async (description: string) => {
    try {
      const content = isProject
        ? await generateProjectContent(description)
        : await generateBlogContent(description);

      Object.entries(content).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
    } catch (error) {
      console.error('Error generating content:', error);
      alert('Failed to generate content. Please try again.');
    }
  };

  const handleEnhance = async (field: string, type: string) => {
    const currentValue = watch(field);
    if (!currentValue) return;

    setEnhancing(field);
    try {
      const enhanced = await enhanceText(currentValue, type);
      setValue(field, enhanced, { shouldValidate: true });
    } finally {
      setEnhancing(null);
    }
  };

  const onSubmit = async (data: ProjectFormType | BlogFormType) => {
    try {
      if (!mainImage[0]) {
        alert('Please upload a main image');
        return;
      }

      const processedForm = processFormData(data, isProject ? 'project' : 'blog');
      
      if (processedForm.errors) {
        console.error('Form validation errors:', processedForm.errors);
        return;
      }

      if (isProject) {
        const projectData = {
          ...processedForm.data,
          image: mainImage[0],
          gallery: [...galleryImages]
        };
        
        if (isEditing) {
          await updateProject(id, projectData);
        } else {
          await createProject(projectData);
        }
      } else {
        if (!authorAvatar[0] && !isEditing) {
          alert('Please upload an author avatar');
          return;
        }

        const postData = {
          ...processedForm.data,
          image: mainImage[0],
          author: {
            ...data.author,
            avatar: authorAvatar[0] || item?.author?.avatar
          },
          date: new Date().toISOString().split('T')[0],
          readTime: '5 min' // You might want to calculate this based on content length
        };

        if (isEditing) {
          await updatePost(id, postData);
        } else {
          await createPost(postData);
        }
      }

      navigate(`/admin/${isProject ? 'projects' : 'posts'}`);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(`Failed to ${isEditing ? 'update' : 'create'} ${isProject ? 'project' : 'post'}`);
    }
  };

  if ((projectsLoading || postsLoading) && isEditing) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <AdminNav />
        <main className="py-10">
          <div className="max-w-3xl mx-auto px-4">
            <LoadingSpinner />
          </div>
        </main>
      </div>
    );
  }

  if ((projectsError || postsError) && isEditing) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <AdminNav />
        <main className="py-10">
          <div className="max-w-3xl mx-auto px-4">
            <ErrorMessage 
              message={projectsError?.message || postsError?.message || 'An error occurred'}
              onRetry={() => navigate(`/admin/${isProject ? 'projects' : 'posts'}`)}
            />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <AdminNav />
      
      <main className="py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-8 flex items-center justify-between">
            <button
              onClick={() => navigate(-1)}
              className="inline-flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {isEditing ? 'Edit' : 'Create'} {isProject ? 'Project' : 'Blog Post'}
            </h1>
          </div>

          {!isEditing && (
            <AiFormGenerator
              type={isProject ? 'project' : 'post'}
              onGenerate={handleGenerate}
            />
          )}

          <motion.form
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 space-y-6"
          >
            {isProject ? (
              <ProjectForm
                register={register}
                errors={errors}
                mainImage={mainImage}
                setMainImage={setMainImage}
                galleryImages={galleryImages}
                setGalleryImages={setGalleryImages}
                enhancing={enhancing}
                onEnhance={handleEnhance}
              />
            ) : (
              <BlogForm
                register={register}
                errors={errors}
                mainImage={mainImage}
                setMainImage={setMainImage}
                authorAvatar={authorAvatar}
                setAuthorAvatar={setAuthorAvatar}
                enhancing={enhancing}
                onEnhance={handleEnhance}
              />
            )}

            <div className="pt-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
              >
                {isEditing ? (
                  <>
                    <Save className="w-4 h-4" />
                    Save Changes
                  </>
                ) : (
                  <>
                    <Plus className="w-4 h-4" />
                    Create {isProject ? 'Project' : 'Blog Post'}
                  </>
                )}
              </button>
            </div>
          </motion.form>
        </div>
      </main>
    </div>
  );
};

export default AdminFormPage;