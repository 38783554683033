import React from 'react';
import { Sparkles } from 'lucide-react';
import { motion } from 'framer-motion';

interface EnhanceButtonProps {
  onClick: () => void;
  loading?: boolean;
}

const EnhanceButton: React.FC<EnhanceButtonProps> = ({ onClick, loading }) => {
  return (
    <motion.button
      type="button"
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className={`p-2 rounded-lg ${
        loading
          ? 'bg-purple-100 dark:bg-purple-900/30 cursor-wait'
          : 'bg-gray-100 dark:bg-gray-700 hover:bg-purple-100 dark:hover:bg-purple-900/30'
      } transition-colors`}
      disabled={loading}
    >
      <Sparkles
        className={`w-4 h-4 ${
          loading
            ? 'text-purple-600 dark:text-purple-400 animate-pulse'
            : 'text-gray-600 dark:text-gray-400'
        }`}
      />
    </motion.button>
  );
};

export default EnhanceButton;