import { StorageConfig } from './types';

export const storageConfig: StorageConfig = {
  maxSizeMB: 0.75, // 750KB to stay under Firestore's 1MB limit
  maxWidth: 1200, // Reduced from 1920 to help with file size
  maxHeight: 1200, // Reduced from 1080 to help with file size
  allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.webp'],
  compressionQuality: 0.8,
  acceptedMimeTypes: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/webp': ['.webp']
  }
};