import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { BlogPost } from '../types';
import { getPosts as fetchPosts, createPost as createPostDb, updatePost as updatePostDb, deletePost as deletePostDb } from '../lib/db';

export const usePosts = (onlyShowOnHomePage = false) => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    loadPosts();
  }, [onlyShowOnHomePage]);

  const loadPosts = async () => {
    try {
      setLoading(true);
      let postsQuery = collection(db, 'posts');
      
      if (onlyShowOnHomePage) {
        postsQuery = query(postsQuery, where('showOnHomePage', '==', true));
      }
      
      const snapshot = await getDocs(postsQuery);
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as BlogPost[];
      
      setPosts(postsData);
      setError(null);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const createPost = async (post: Omit<BlogPost, 'id'>) => {
    try {
      const newPost = await createPostDb(post);
      setPosts(prevPosts => [...prevPosts, newPost]);
      return newPost;
    } catch (err) {
      const error = err as Error;
      setError(error);
      throw error;
    }
  };

  const updatePost = async (id: string, post: Partial<BlogPost>) => {
    try {
      const updatedPost = await updatePostDb(id, post);
      setPosts(prevPosts => prevPosts.map(p => p.id === id ? updatedPost : p));
      return updatedPost;
    } catch (err) {
      const error = err as Error;
      setError(error);
      throw error;
    }
  };

  const deletePost = async (id: string) => {
    try {
      await deletePostDb(id);
      setPosts(prevPosts => prevPosts.filter(p => p.id !== id));
    } catch (err) {
      const error = err as Error;
      setError(error);
      throw error;
    }
  };

  return {
    posts,
    loading,
    error,
    createPost,
    updatePost,
    deletePost,
    refresh: loadPosts
  };
};