import type { ProjectForm, BlogForm, ProcessedForm } from '../types/forms';
import { parseTags, parseNewlines } from './stringUtils';

/**
 * Processes form data and ensures consistent type handling
 */
export const processFormData = <T extends ProjectForm | BlogForm>(
  data: T,
  type: 'project' | 'blog'
): ProcessedForm<T> => {
  console.log(`Processing ${type} form data:`, data);

  try {
    if (type === 'project') {
      const processed = {
        ...data,
        tags: parseTags(data.tags),
        technologies: parseTags(data.technologies),
        features: parseNewlines(data.features),
        challenges: parseNewlines(data.challenges),
        solutions: parseNewlines(data.solutions),
        gallery: Array.isArray(data.gallery) ? data.gallery : []
      } as T;

      console.log('Processed project data:', processed);
      return { data: processed };
    } else {
      const processed = {
        ...data,
        tags: parseTags(data.tags)
      } as T;

      console.log('Processed blog data:', processed);
      return { data: processed };
    }
  } catch (error) {
    console.error(`Error processing ${type} form:`, error);
    return {
      data: data,
      errors: {
        processing: 'Failed to process form data'
      }
    };
  }
};

/**
 * Formats data for form display
 */
export const formatFormData = <T extends ProjectForm | BlogForm>(
  data: T,
  type: 'project' | 'blog'
): T => {
  if (type === 'project') {
    return {
      ...data,
      tags: Array.isArray(data.tags) ? data.tags.join(', ') : data.tags,
      technologies: Array.isArray(data.technologies) ? data.technologies.join(', ') : data.technologies,
      features: Array.isArray(data.features) ? data.features.join('\n') : data.features,
      challenges: Array.isArray(data.challenges) ? data.challenges.join('\n') : data.challenges,
      solutions: Array.isArray(data.solutions) ? data.solutions.join('\n') : data.solutions
    } as T;
  } else {
    return {
      ...data,
      tags: Array.isArray(data.tags) ? data.tags.join(', ') : data.tags
    } as T;
  }
};

/**
 * Validates form data before submission
 */
export const validateFormData = <T extends ProjectForm | BlogForm>(
  data: T,
  type: 'project' | 'blog'
): ProcessedForm<T> => {
  const errors: Record<string, string> = {};

  if (type === 'project') {
    if (!data.title?.trim()) errors.title = 'Title is required';
    if (!data.description?.trim()) errors.description = 'Description is required';
    if (!data.longDescription?.trim()) errors.longDescription = 'Long description is required';
    if (!parseTags(data.tags).length) errors.tags = 'At least one tag is required';
    if (!parseTags(data.technologies).length) errors.technologies = 'At least one technology is required';
    if (!parseNewlines(data.features).length) errors.features = 'At least one feature is required';
  } else {
    if (!data.title?.trim()) errors.title = 'Title is required';
    if (!data.excerpt?.trim()) errors.excerpt = 'Excerpt is required';
    if (!data.content?.trim()) errors.content = 'Content is required';
    if (!parseTags(data.tags).length) errors.tags = 'At least one tag is required';
  }

  return {
    data,
    errors: Object.keys(errors).length ? errors : undefined
  };
};