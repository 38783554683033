/**
 * Converts a comma-separated string of tags into an array of trimmed strings.
 * Handles empty inputs and maintains the original order.
 * 
 * @param input - A string containing tags separated by commas, or an array of strings
 * @returns An array of trimmed tag strings
 */
export const parseTags = (input: string | string[] | undefined): string[] => {
  // Handle array input
  if (Array.isArray(input)) {
    return input.map(tag => tag.trim()).filter(Boolean);
  }
  
  // Handle string input
  if (typeof input === 'string' && input.trim()) {
    return input
      .split(',')
      .map(tag => tag.trim())
      .filter(Boolean);
  }
  
  return [];
};

/**
 * Converts a newline-separated string into an array of trimmed strings.
 * 
 * @param input - A string containing items separated by newlines, or an array of strings
 * @returns An array of trimmed strings
 */
export const parseNewlines = (input: string | string[] | undefined): string[] => {
  // Handle array input
  if (Array.isArray(input)) {
    return input.map(item => item.trim()).filter(Boolean);
  }
  
  // Handle string input
  if (typeof input === 'string' && input.trim()) {
    return input
      .split('\n')
      .map(item => item.trim())
      .filter(Boolean);
  }
  
  return [];
};

/**
 * Formats an array of strings into a comma-separated string.
 * 
 * @param arr - Array of strings to format
 * @returns A comma-separated string
 */
export const formatToString = (arr: string[] | undefined): string => {
  if (!Array.isArray(arr)) return '';
  return arr.join(', ');
};