import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Edit2, Trash2, ExternalLink, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import AdminNav from '../components/AdminNav';
import DeleteConfirmModal from '../components/DeleteConfirmModal';
import { usePosts } from '../hooks/usePosts';
import { BlogPost } from '../lib/db';

const AdminPostsPage: React.FC = () => {
  const { posts, deletePost, updatePost } = usePosts();
  const [deleteModal, setDeleteModal] = useState<{ show: boolean; post?: BlogPost }>({
    show: false
  });

  const handleDelete = async (post: BlogPost) => {
    try {
      await deletePost(post.id);
      setDeleteModal({ show: false });
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const toggleShowOnHomePage = async (post: BlogPost) => {
    try {
      await updatePost(post.id, {
        ...post,
        showOnHomePage: !post.showOnHomePage
      });
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <AdminNav />
      
      <main className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              Blog Posts
            </h1>
            <Link
              to="/admin/creation/post"
              className="inline-flex items-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
            >
              <Plus className="w-4 h-4" />
              New Post
            </Link>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              <AnimatePresence>
                {posts.map((post) => (
                  <motion.li
                    key={post.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="p-6 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0 w-16 h-16 rounded-lg overflow-hidden">
                          <img
                            src={post.image}
                            alt={post.title}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div>
                          <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                            {post.title}
                          </h2>
                          <div className="flex items-center space-x-4 mt-1">
                            <span className="text-sm text-purple-600 dark:text-purple-400">
                              {post.category}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {post.date}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <Link
                          to={`/blog/${post.slug}`}
                          target="_blank"
                          className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                        >
                          <ExternalLink className="w-5 h-5" />
                        </Link>
                        <button
                          onClick={() => toggleShowOnHomePage(post)}
                          className={`text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 ${
                            post.showOnHomePage ? 'text-purple-600 dark:text-purple-400' : ''
                          }`}
                          title={post.showOnHomePage ? 'Remove from homepage' : 'Show on homepage'}
                        >
                          <Home className="w-5 h-5" />
                        </button>
                        <Link
                          to={`/admin/posts/${post.id}/edit`}
                          className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                        >
                          <Edit2 className="w-5 h-5" />
                        </Link>
                        <button
                          onClick={() => setDeleteModal({ show: true, post })}
                          className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </motion.li>
                ))}
              </AnimatePresence>
            </ul>
          </div>
        </div>
      </main>

      <DeleteConfirmModal
        isOpen={deleteModal.show}
        onClose={() => setDeleteModal({ show: false })}
        onConfirm={() => deleteModal.post && handleDelete(deleteModal.post)}
        title="Delete Blog Post"
        message="Are you sure you want to delete this blog post? This action cannot be undone."
      />
    </div>
  );
};

export default AdminPostsPage;