import React from 'react';
import { motion } from 'framer-motion';
import { Mail } from 'lucide-react';
import { useScrollAnimation, fadeInUpVariants, staggerChildrenVariants } from '../hooks/useScrollAnimation';

const Contact: React.FC = () => {
  const { ref, controls } = useScrollAnimation();

  const contactInfo = [
    {
      icon: Mail,
      title: 'Email',
      content: 'contact@virtuoz.solutions',
      href: 'mailto:contact@virtuoz.solutions',
      primary: true
    }
  ];

  return (
    <section id="contact" className="py-32">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={staggerChildrenVariants}
        className="container mx-auto px-4 max-w-4xl"
      >
        <motion.h2 
          variants={fadeInUpVariants}
          className="text-4xl font-bold text-white mb-16 text-center tracking-tight"
        >
          Contact Us
        </motion.h2>
        <motion.div 
          variants={staggerChildrenVariants}
          className="space-y-8"
        >
          {contactInfo.map((item, index) => (
            <motion.div
              key={index}
              variants={fadeInUpVariants}
              className={`flex items-start space-x-6 p-6 rounded-2xl transition-all duration-300 ${
                item.primary
                  ? 'bg-purple-500/10 hover:bg-purple-500/20'
                  : 'hover:bg-gray-800'
              }`}
            >
              <div className={`rounded-full p-3 ${
                item.primary
                  ? 'bg-purple-500/20'
                  : 'bg-gray-800'
              }`}>
                <item.icon className={`w-6 h-6 ${
                  item.primary
                    ? 'text-purple-400'
                    : 'text-gray-400'
                }`} />
              </div>
              <div className="flex-grow">
                <h3 className="text-lg font-semibold text-white mb-1">
                  {item.title}
                </h3>
                {item.href ? (
                  <a
                    href={item.href}
                    className={`group inline-flex items-center gap-2 ${
                      item.primary
                        ? 'text-purple-400 hover:text-purple-300'
                        : 'text-gray-400 hover:text-white'
                    } transition-colors`}
                  >
                    {item.content}
                  </a>
                ) : (
                  <p className="text-gray-400 whitespace-pre-line">
                    {item.content}
                  </p>
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Contact;