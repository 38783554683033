import React from 'react';
import EnhanceButton from './EnhanceButton';

interface FormFieldProps {
  label: string;
  error?: string;
  enhanceLoading?: boolean;
  onEnhance?: () => void;
  showEnhance?: boolean;
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  error,
  enhanceLoading,
  onEnhance,
  showEnhance = true,
  children
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
        {label}
      </label>
      <div className="flex gap-2">
        {children}
        {showEnhance && onEnhance && (
          <EnhanceButton onClick={onEnhance} loading={enhanceLoading} />
        )}
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {error}
        </p>
      )}
    </div>
  );
};

export default FormField;