import { z } from 'zod';
import { parseTags, parseNewlines } from './stringUtils';

// Create a schema that handles both string and array inputs for comma-separated values
const createTagsSchema = (options: { required?: boolean; minItems?: number; errorMessage?: string }) => {
  let schema = z.union([
    z.string(),
    z.array(z.string())
  ]).transform(parseTags);

  if (options.required || options.minItems) {
    schema = schema.refine(
      (arr) => !options.minItems || arr.length >= options.minItems,
      { message: options.errorMessage || `At least ${options.minItems} items required` }
    );
  }

  return schema;
};

// Create a schema that handles both string and array inputs for newline-separated values
const createNewlineSchema = (options: { required?: boolean; minItems?: number; errorMessage?: string }) => {
  let schema = z.union([
    z.string(),
    z.array(z.string())
  ]).transform(parseNewlines);

  if (options.required || options.minItems) {
    schema = schema.refine(
      (arr) => !options.minItems || arr.length >= options.minItems,
      { message: options.errorMessage || `At least ${options.minItems} items required` }
    );
  }

  return schema;
};

// Form validation schemas
export const projectValidationSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  slug: z.string().min(1, 'Slug is required'),
  description: z.string().min(1, 'Description is required'),
  longDescription: z.string().min(1, 'Long description is required'),
  image: z.string().optional(),
  gallery: z.array(z.string()).optional().default([]),
  showOnHomePage: z.boolean().default(false),
  tags: createTagsSchema({ 
    required: true, 
    minItems: 1, 
    errorMessage: 'At least one tag is required' 
  }),
  technologies: createTagsSchema({ 
    required: true, 
    minItems: 1, 
    errorMessage: 'At least one technology is required' 
  }),
  features: createNewlineSchema({ 
    required: true, 
    minItems: 1, 
    errorMessage: 'At least one feature is required' 
  }),
  challenges: createNewlineSchema({}),
  solutions: createNewlineSchema({}),
  links: z.object({
    demo: z.string().url('Must be a valid URL'),
    github: z.string().url('Must be a valid URL')
  })
});

export const blogValidationSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  slug: z.string().min(1, 'Slug is required'),
  excerpt: z.string().min(1, 'Excerpt is required'),
  content: z.string().min(1, 'Content is required'),
  image: z.string().optional(),
  category: z.string().min(1, 'Category is required'),
  showOnHomePage: z.boolean().default(false),
  tags: createTagsSchema({ 
    required: true, 
    minItems: 1, 
    errorMessage: 'At least one tag is required' 
  }),
  author: z.object({
    name: z.string().min(1, 'Author name is required'),
    avatar: z.string().optional(),
    role: z.string().min(1, 'Author role is required')
  })
});