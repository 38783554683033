import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { getProject } from '../lib/db';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import ImageLightbox from '../components/ImageLightbox';
import { ArrowLeft, ExternalLink } from 'lucide-react';
import type { Project } from '../types';

const ProjectDetailPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { darkMode, toggleDarkMode } = useTheme();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    const loadProject = async () => {
      if (!slug) {
        navigate('/projects');
        return;
      }
      
      try {
        setLoading(true);
        const projectData = await getProject(slug);
        if (!projectData) {
          throw new Error('Project not found');
        }
        setProject(projectData);
        setError(null);
      } catch (err) {
        console.error('Error loading project:', err);
        setError(err instanceof Error ? err : new Error('Failed to load project'));
      } finally {
        setLoading(false);
      }
    };
    
    loadProject();
  }, [slug, navigate]);

  if (loading) {
    return (
      <div className={darkMode ? 'dark' : ''}>
        <div className="bg-white dark:bg-gray-900 min-h-screen">
          <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <main className="container mx-auto px-4 py-32">
            <LoadingSpinner />
          </main>
          <Footer />
        </div>
      </div>
    );
  }

  const allImages = project ? [project.image, ...(project.gallery || [])] : [];

  const openLightbox = (index: number) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  return (
    <div className={darkMode ? 'dark' : ''}>
      <div className="bg-white dark:bg-gray-900 min-h-screen transition-colors duration-200">
        <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        
        <main className="container mx-auto px-4 py-32">
          {error ? (
            <ErrorMessage
              message={error.message}
              onRetry={() => navigate('/projects')}
            />
          ) : project && (
            <div className="max-w-4xl mx-auto">
              <Link 
                to="/projects"
                className="inline-flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white mb-8"
              >
                <ArrowLeft className="w-4 h-4" />
                Back to Projects
              </Link>

              <motion.article
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <div 
                  className="aspect-video rounded-2xl overflow-hidden mb-8 cursor-pointer shadow-xl"
                  onClick={() => openLightbox(0)}
                >
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-contain transition-transform duration-300 hover:scale-105"
                  />
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 md:p-12 mb-8">
                  <div className="flex flex-wrap gap-4 mb-6">
                    {project.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400 rounded-full text-sm"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">
                    {project.title}
                  </h1>

                  <div className="prose prose-lg dark:prose-invert max-w-none mb-8">
                    <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed mb-8">
                      {project.description}
                    </p>
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                      Project Overview
                    </h2>
                    <div className="text-gray-600 dark:text-gray-300 leading-relaxed">
                      {project.longDescription}
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-4">
                    <a
                      href={project.links.demo}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center gap-2 px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-full font-medium transition-colors"
                    >
                      <ExternalLink className="w-4 h-4" />
                      View Live Demo
                    </a>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                  <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                      Technologies Used
                    </h2>
                    <ul className="space-y-3">
                      {project.technologies.map((tech, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-3 text-gray-600 dark:text-gray-300"
                        >
                          <span className="w-2 h-2 bg-purple-500 rounded-full" />
                          {tech}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                      Key Features
                    </h2>
                    <ul className="space-y-3">
                      {project.features.map((feature, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-3 text-gray-600 dark:text-gray-300"
                        >
                          <span className="w-2 h-2 bg-purple-500 rounded-full" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                      Challenges
                    </h2>
                    <ul className="space-y-3">
                      {project.challenges.map((challenge, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-3 text-gray-600 dark:text-gray-300"
                        >
                          <span className="w-2 h-2 bg-purple-500 rounded-full" />
                          {challenge}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                      Solutions
                    </h2>
                    <ul className="space-y-3">
                      {project.solutions.map((solution, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-3 text-gray-600 dark:text-gray-300"
                        >
                          <span className="w-2 h-2 bg-purple-500 rounded-full" />
                          {solution}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {project.gallery?.length > 0 && (
                  <div className="mt-12">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                      Project Gallery
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {project.gallery.map((image, index) => (
                        <motion.div
                          key={index}
                          whileHover={{ scale: 1.02 }}
                          className="aspect-[4/3] rounded-xl overflow-hidden cursor-pointer shadow-lg"
                          onClick={() => openLightbox(index + 1)}
                        >
                          <img
                            src={image}
                            alt={`${project.title} screenshot ${index + 1}`}
                            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                          />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                )}
              </motion.article>
            </div>
          )}
        </main>
        
        <Footer />

        {lightboxOpen && (
          <ImageLightbox
            images={allImages}
            currentIndex={currentImageIndex}
            onClose={() => setLightboxOpen(false)}
            onNavigate={setCurrentImageIndex}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetailPage;