import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import FormField from './FormField';
import ImageUpload from './ImageUpload';
import type { BlogForm } from '../types/forms';

interface BlogFormProps {
  register: UseFormRegister<BlogForm>;
  errors: FieldErrors<BlogForm>;
  mainImage: string[];
  setMainImage: (images: string[]) => void;
  authorAvatar: string[];
  setAuthorAvatar: (images: string[]) => void;
  enhancing: string | null;
  onEnhance: (field: string, type: string) => void;
}

const BlogForm: React.FC<BlogFormProps> = ({
  register,
  errors,
  mainImage,
  setMainImage,
  authorAvatar,
  setAuthorAvatar,
  enhancing,
  onEnhance
}) => {
  return (
    <div className="space-y-6">
      <FormField
        label="Title"
        error={errors.title?.message}
        enhanceLoading={enhancing === 'title'}
        onEnhance={() => onEnhance('title', 'title')}
      >
        <input
          type="text"
          {...register('title')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter post title"
        />
      </FormField>

      <FormField
        label="Show on Homepage"
        error={errors.showOnHomePage?.message}
        showEnhance={false}
      >
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            {...register('showOnHomePage')}
            className="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500 dark:border-gray-600 dark:bg-gray-700"
          />
          <span className="text-gray-700 dark:text-gray-300">Display this post on the homepage</span>
        </label>
      </FormField>

      <FormField
        label="Slug"
        error={errors.slug?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('slug')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter URL-friendly slug"
        />
      </FormField>

      <FormField
        label="Excerpt"
        error={errors.excerpt?.message}
        enhanceLoading={enhancing === 'excerpt'}
        onEnhance={() => onEnhance('excerpt', 'description')}
      >
        <input
          type="text"
          {...register('excerpt')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter post excerpt"
        />
      </FormField>

      <FormField
        label="Content"
        error={errors.content?.message}
        enhanceLoading={enhancing === 'content'}
        onEnhance={() => onEnhance('content', 'content')}
      >
        <div className="space-y-2">
          <textarea
            {...register('content')}
            rows={12}
            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white font-mono"
            placeholder="Enter post content (Markdown supported)"
          />
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Markdown formatting is supported. Use # for headings, * for lists, etc.
          </p>
        </div>
      </FormField>

      <FormField
        label="Main Image"
        error={errors.image?.message}
        showEnhance={false}
      >
        <ImageUpload
          images={mainImage}
          onImagesChange={setMainImage}
          multiple={false}
        />
      </FormField>

      <FormField
        label="Category"
        error={errors.category?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('category')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter post category"
        />
      </FormField>

      <div className="grid grid-cols-2 gap-4">
        <FormField
          label="Author Name"
          error={errors.author?.name?.message}
          showEnhance={false}
        >
          <input
            type="text"
            {...register('author.name')}
            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
            placeholder="Enter author name"
          />
        </FormField>

        <FormField
          label="Author Role"
          error={errors.author?.role?.message}
          showEnhance={false}
        >
          <input
            type="text"
            {...register('author.role')}
            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
            placeholder="Enter author role"
          />
        </FormField>
      </div>

      <FormField
        label="Author Avatar"
        error={errors.author?.avatar?.message}
        showEnhance={false}
      >
        <ImageUpload
          images={authorAvatar}
          onImagesChange={setAuthorAvatar}
          multiple={false}
        />
      </FormField>

      <FormField
        label="Tags"
        error={errors.tags?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('tags')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter tags (comma-separated)"
        />
      </FormField>
    </div>
  );
};

export default BlogForm;