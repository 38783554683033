import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FileText, FolderPlus } from 'lucide-react';
import AdminNav from '../components/AdminNav';
import type { Project, BlogPost } from '../types';
import { seedFirestore } from '../utils/seedFirestore';
import { getProjects, getPosts } from '../lib/db';

const AdminDashboard: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [seeding, setSeeding] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectsData, postsData] = await Promise.all([
          getProjects(),
          getPosts()
        ]);
        setProjects(projectsData);
        setPosts(postsData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'Failed to load data');
      }
    };
    fetchData();
  }, []);

  const handleSeed = async () => {
    try {
      setSeeding(true);
      const { projectId, postId } = await seedFirestore();
      alert(`Successfully added sample data!\nProject ID: ${projectId}\nPost ID: ${postId}`);
    } catch (error) {
      console.error('Error seeding data:', error);
      alert('Failed to seed data. Check console for details.');
    } finally {
      setSeeding(false);
    }
  };

  const stats = [
    {
      name: 'Total Projects',
      value: projects.length,
      icon: FolderPlus,
      link: '/admin/projects'
    },
    {
      name: 'Total Blog Posts',
      value: posts.length,
      icon: FileText,
      link: '/admin/posts'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <AdminNav />
      
      <main className="py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Dashboard
          </h1>
          
          {error && (
            <div className="mb-8 p-4 bg-red-100 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg">
              {error}
            </div>
          )}
          
          <div className="mb-8">
            <button
              onClick={handleSeed}
              disabled={seeding}
              className="px-4 py-2 bg-purple-600 hover:bg-purple-700 disabled:bg-purple-400 text-white rounded-lg transition-colors"
            >
              {seeding ? 'Adding Sample Data...' : 'Add Sample Data'}
            </button>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
            {stats.map((stat) => (
              <Link
                key={stat.name}
                to={stat.link}
                className="block"
              >
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-white dark:bg-gray-800 overflow-hidden rounded-lg shadow hover:shadow-lg transition-shadow"
                >
                  <div className="p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-purple-100 dark:bg-purple-900/30 rounded-full p-3">
                        <stat.icon className="w-6 h-6 text-purple-600 dark:text-purple-400" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                            {stat.name}
                          </dt>
                          <dd>
                            <div className="text-lg font-medium text-gray-900 dark:text-white">
                              {stat.value}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Link>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;