import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Project, BlogPost } from '../types';

const sampleProject: Omit<Project, 'id'> = {
  title: 'AI-Powered Analytics Dashboard',
  description: 'Real-time analytics platform with machine learning insights',
  longDescription: 'A comprehensive analytics platform leveraging AI for real-time insights.',
  image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f',
  gallery: [],
  tags: ['React', 'TypeScript', 'AI', 'Analytics'],
  technologies: ['React', 'TypeScript', 'TensorFlow.js'],
  features: ['Real-time data processing', 'AI-powered insights'],
  challenges: ['Processing large datasets'],
  solutions: ['Optimized algorithms'],
  links: {
    demo: 'https://demo.virtuoz.solutions',
    github: 'https://github.com/virtuoz/analytics'
  }
};

const samplePost: Omit<BlogPost, 'id'> = {
  title: 'The Future of AI in Web Development',
  slug: 'future-of-ai-in-web-development',
  excerpt: 'Exploring how artificial intelligence is revolutionizing web development.',
  content: '# The Future of AI in Web Development\n\nArtificial Intelligence is rapidly transforming the landscape of web development...',
  date: new Date().toISOString().split('T')[0],
  readTime: '5 min',
  image: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e',
  category: 'Technology',
  tags: ['AI', 'Web Development', 'Future Tech'],
  author: {
    name: 'Alex Thompson',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    role: 'Senior Developer'
  }
};

export const seedFirestore = async () => {
  try {
    const projectRef = await addDoc(collection(db, 'projects'), {
      ...sampleProject,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });
    console.log('Added sample project with ID:', projectRef.id);

    const postRef = await addDoc(collection(db, 'posts'), {
      ...samplePost,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    });
    console.log('Added sample blog post with ID:', postRef.id);

    return { projectId: projectRef.id, postId: postRef.id };
  } catch (error) {
    console.error('Error seeding Firestore:', error);
    throw error;
  }
};