import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import FormField from './FormField';
import ImageUpload from './ImageUpload';
import type { ProjectForm } from '../types/forms';

interface ProjectFormProps {
  register: UseFormRegister<ProjectForm>;
  errors: FieldErrors<ProjectForm>;
  mainImage: string[];
  setMainImage: (images: string[]) => void;
  galleryImages: string[];
  setGalleryImages: (images: string[]) => void;
  enhancing: string | null;
  onEnhance: (field: string, type: string) => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  register,
  errors,
  mainImage,
  setMainImage,
  galleryImages,
  setGalleryImages,
  enhancing,
  onEnhance
}) => {
  return (
    <div className="space-y-6">
      <FormField
        label="Title"
        error={errors.title?.message}
        enhanceLoading={enhancing === 'title'}
        onEnhance={() => onEnhance('title', 'title')}
      >
        <input
          type="text"
          {...register('title')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter project title"
        />
      </FormField>

      <FormField
        label="Show on Homepage"
        error={errors.showOnHomePage?.message}
        showEnhance={false}
      >
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            {...register('showOnHomePage')}
            className="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500 dark:border-gray-600 dark:bg-gray-700"
          />
          <span className="text-gray-700 dark:text-gray-300">Display this project on the homepage</span>
        </label>
      </FormField>

      {/* Rest of the existing form fields */}
      <FormField
        label="Slug"
        error={errors.slug?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('slug')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter URL-friendly slug"
        />
      </FormField>

      <FormField
        label="Short Description"
        error={errors.description?.message}
        enhanceLoading={enhancing === 'description'}
        onEnhance={() => onEnhance('description', 'description')}
      >
        <input
          type="text"
          {...register('description')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter a brief description"
        />
      </FormField>

      <FormField
        label="Long Description"
        error={errors.longDescription?.message}
        enhanceLoading={enhancing === 'longDescription'}
        onEnhance={() => onEnhance('longDescription', 'content')}
      >
        <textarea
          {...register('longDescription')}
          rows={6}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter detailed description"
        />
      </FormField>

      <FormField
        label="Main Image"
        error={errors.image?.message}
        showEnhance={false}
      >
        <ImageUpload
          images={mainImage}
          onImagesChange={setMainImage}
          multiple={false}
        />
      </FormField>

      <FormField
        label="Gallery Images"
        error={errors.gallery?.message}
        showEnhance={false}
      >
        <ImageUpload
          images={galleryImages}
          onImagesChange={setGalleryImages}
          multiple={true}
        />
      </FormField>

      <FormField
        label="Technologies"
        error={errors.technologies?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('technologies')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter technologies (comma-separated)"
        />
      </FormField>

      <FormField
        label="Features"
        error={errors.features?.message}
        showEnhance={false}
      >
        <textarea
          {...register('features')}
          rows={3}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter features (one per line)"
        />
      </FormField>

      <FormField
        label="Challenges"
        error={errors.challenges?.message}
        showEnhance={false}
      >
        <textarea
          {...register('challenges')}
          rows={3}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter challenges (one per line)"
        />
      </FormField>

      <FormField
        label="Solutions"
        error={errors.solutions?.message}
        showEnhance={false}
      >
        <textarea
          {...register('solutions')}
          rows={3}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter solutions (one per line)"
        />
      </FormField>

      <div className="grid grid-cols-2 gap-4">
        <FormField
          label="Demo URL"
          error={errors.links?.demo?.message}
          showEnhance={false}
        >
          <input
            type="text"
            {...register('links.demo')}
            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
            placeholder="Enter demo URL"
          />
        </FormField>

        <FormField
          label="GitHub URL"
          error={errors.links?.github?.message}
          showEnhance={false}
        >
          <input
            type="text"
            {...register('links.github')}
            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
            placeholder="Enter GitHub URL"
          />
        </FormField>
      </div>

      <FormField
        label="Tags"
        error={errors.tags?.message}
        showEnhance={false}
      >
        <input
          type="text"
          {...register('tags')}
          className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          placeholder="Enter tags (comma-separated)"
        />
      </FormField>
    </div>
  );
};

export default ProjectForm;