import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { Project } from '../types';
import { getProjects as fetchProjects, createProject as createProjectDb, updateProject as updateProjectDb, deleteProject as deleteProjectDb } from '../lib/db';

export const useProjects = (onlyShowOnHomePage = false) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    loadProjects();
  }, [onlyShowOnHomePage]);

  const loadProjects = async () => {
    try {
      setLoading(true);
      let projectsQuery = collection(db, 'projects');
      
      if (onlyShowOnHomePage) {
        projectsQuery = query(projectsQuery, where('showOnHomePage', '==', true));
      }
      
      const snapshot = await getDocs(projectsQuery);
      const projectsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Project[];
      
      setProjects(projectsData);
      setError(null);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const createProject = async (project: Omit<Project, 'id'>) => {
    try {
      const newProject = await createProjectDb(project);
      setProjects(prevProjects => [...prevProjects, newProject]);
      return newProject;
    } catch (err) {
      throw err;
    }
  };

  const updateProject = async (id: string, project: Partial<Project>) => {
    try {
      const updatedProject = await updateProjectDb(id, project);
      const updatedProjects = projects.map((p) =>
        p.id === id ? updatedProject : p
      );
      setProjects(updatedProjects);
      return updatedProject;
    } catch (err) {
      throw err;
    }
  };

  const deleteProject = async (id: string) => {
    try {
      await deleteProjectDb(id);
      const filteredProjects = projects.filter((p) => p.id !== id);
      setProjects(filteredProjects);
    } catch (err) {
      throw err;
    }
  };

  return {
    projects,
    loading,
    error,
    createProject,
    updateProject,
    deleteProject,
    refresh: loadProjects
  };
};