import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { processImageForStorage } from '../utils/storage/base64';
import { storageConfig } from '../utils/storage/config';

interface Base64ImageUploadProps {
  images: string[];
  onImagesChange: (images: string[]) => void;
  multiple?: boolean;
}

const Base64ImageUpload: React.FC<Base64ImageUploadProps> = ({
  images,
  onImagesChange,
  multiple = false
}) => {
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const processedImages = await Promise.all(
        acceptedFiles.map(file => processImageForStorage(file))
      );

      if (multiple) {
        onImagesChange([...images, ...processedImages]);
      } else {
        onImagesChange([processedImages[0]]);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      alert(error instanceof Error ? error.message : 'Upload failed');
    }
  }, [images, onImagesChange, multiple]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: storageConfig.acceptedMimeTypes,
    multiple,
    maxSize: storageConfig.maxSizeMB * 1024 * 1024
  });

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onImagesChange(newImages);
  };

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors ${
          isDragActive
            ? 'border-purple-500 bg-purple-50 dark:bg-purple-900/10'
            : 'border-gray-300 dark:border-gray-600 hover:border-purple-500 dark:hover:border-purple-500'
        }`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {isDragActive ? (
            'Drop the files here...'
          ) : (
            <>
              Drag & drop images here, or click to select
              <br />
              <span className="text-xs">
                Supported formats: {storageConfig.allowedExtensions.join(', ')}
                <br />
                Max size: {storageConfig.maxSizeMB}MB
              </span>
            </>
          )}
        </p>
      </div>

      {images.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <AnimatePresence>
            {images.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                className="relative aspect-square rounded-lg overflow-hidden group"
              >
                <img
                  src={image}
                  alt={`Upload ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default Base64ImageUpload;