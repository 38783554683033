import React from 'react';
import Base64ImageUpload from './Base64ImageUpload';

interface ImageUploadProps {
  images: string[];
  onImagesChange: (images: string[]) => void;
  multiple?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  images,
  onImagesChange,
  multiple = false
}) => {
  return (
    <Base64ImageUpload
      images={images}
      onImagesChange={onImagesChange}
      multiple={multiple}
    />
  );
};

export default ImageUpload;