import { collection, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, query, where, Timestamp, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { Project, BlogPost } from '../types';
import type { FirestoreProject, FirestorePost } from '../types/database';

const PROJECTS_COLLECTION = 'projects';
const POSTS_COLLECTION = 'posts';

// Project Operations
export const getProjects = async (): Promise<Project[]> => {
  console.log('Fetching projects from Firestore...');
  try {
    const querySnapshot = await getDocs(collection(db, PROJECTS_COLLECTION));
    const projects = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date?.toDate?.()?.toISOString?.() || doc.data().date
    } as Project));
    return projects;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw new Error('Failed to fetch projects');
  }
};

export const getProject = async (slug: string): Promise<Project | undefined> => {
  try {
    const q = query(collection(db, PROJECTS_COLLECTION), where('slug', '==', slug));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return undefined;
    
    const doc = querySnapshot.docs[0];
    return { id: doc.id, ...doc.data() } as Project;
  } catch (error) {
    console.error('Error fetching project:', error);
    throw new Error('Failed to fetch project');
  }
};

export const createProject = async (project: Omit<Project, 'id'>): Promise<Project> => {
  try {
    const firestoreProject: Omit<FirestoreProject, 'id'> = {
      ...project,
      createdAt: serverTimestamp() as Timestamp,
      updatedAt: serverTimestamp() as Timestamp
    };
    
    const docRef = await addDoc(collection(db, PROJECTS_COLLECTION), firestoreProject);
    const createdProject = { id: docRef.id, ...project };
    console.log('Successfully created project:', createdProject);
    return createdProject;
  } catch (error) {
    console.error('Error creating project:', error);
    throw new Error('Failed to create project');
  }
};

export const updateProject = async (id: string, project: Partial<Project>): Promise<Project> => {
  try {
    const docRef = doc(db, PROJECTS_COLLECTION, id);
    const updateData = {
      ...project,
      updatedAt: serverTimestamp()
    };
    
    await updateDoc(docRef, updateData);
    const updatedProject = { id, ...project } as Project;
    console.log('Successfully updated project:', updatedProject);
    return updatedProject;
  } catch (error) {
    console.error('Error updating project:', error);
    throw new Error('Failed to update project');
  }
};

export const deleteProject = async (id: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, PROJECTS_COLLECTION, id));
    console.log('Successfully deleted project:', id);
  } catch (error) {
    console.error('Error deleting project:', error);
    throw new Error('Failed to delete project');
  }
};

// Blog Post Operations
export const getPosts = async (): Promise<BlogPost[]> => {
  console.log('Fetching blog posts...');
  try {
    const querySnapshot = await getDocs(collection(db, POSTS_COLLECTION));
    const posts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date?.toDate?.()?.toISOString?.() || doc.data().date
    } as BlogPost));
    return posts;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw new Error('Failed to fetch posts');
  }
};

export const getPost = async (slug: string): Promise<BlogPost | undefined> => {
  try {
    const q = query(collection(db, POSTS_COLLECTION), where('slug', '==', slug));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return undefined;
    
    const doc = querySnapshot.docs[0];
    return { id: doc.id, ...doc.data() } as BlogPost;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw new Error('Failed to fetch post');
  }
};

export const createPost = async (post: Omit<BlogPost, 'id'>): Promise<BlogPost> => {
  try {
    const firestorePost: Omit<FirestorePost, 'id'> = {
      ...post,
      createdAt: serverTimestamp() as Timestamp,
      updatedAt: serverTimestamp() as Timestamp
    };
    
    const docRef = await addDoc(collection(db, POSTS_COLLECTION), firestorePost);
    const createdPost = { id: docRef.id, ...post };
    console.log('Successfully created post:', createdPost);
    return createdPost;
  } catch (error) {
    console.error('Error creating post:', error);
    throw new Error('Failed to create post');
  }
};

export const updatePost = async (id: string, post: Partial<BlogPost>): Promise<BlogPost> => {
  try {
    const docRef = doc(db, POSTS_COLLECTION, id);
    const updateData = {
      ...post,
      updatedAt: serverTimestamp()
    };
    
    await updateDoc(docRef, updateData);
    const updatedPost = { id, ...post } as BlogPost;
    console.log('Successfully updated post:', updatedPost);
    return updatedPost;
  } catch (error) {
    console.error('Error updating post:', error);
    throw new Error('Failed to update post');
  }
};

export const deletePost = async (id: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, POSTS_COLLECTION, id));
    console.log('Successfully deleted post:', id);
  } catch (error) {
    console.error('Error deleting post:', error);
    throw new Error('Failed to delete post');
  }
};