import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Wand2 } from 'lucide-react';

interface AiFormGeneratorProps {
  type: 'project' | 'post';
  onGenerate: (content: string) => Promise<void>;
}

const AiFormGenerator: React.FC<AiFormGeneratorProps> = ({ type, onGenerate }) => {
  const [description, setDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!description.trim()) {
      setError('Please enter a description');
      return;
    }

    setIsGenerating(true);
    setError(null);
    
    try {
      await onGenerate(description);
      setDescription('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-purple-50 dark:bg-purple-900/20 rounded-xl p-6 mb-8"
    >
      <h3 className="text-lg font-medium text-purple-900 dark:text-purple-100 mb-4">
        AI Content Generator
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-purple-700 dark:text-purple-300 mb-2"
          >
            {type === 'project'
              ? 'Describe your project'
              : 'What would you like to write about?'}
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setError(null);
            }}
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-gray-800 dark:text-white ${
              error
                ? 'border-red-300 dark:border-red-600'
                : 'border-purple-300 dark:border-purple-600'
            }`}
            rows={3}
            placeholder={
              type === 'project'
                ? 'Enter a description of your project...'
                : 'Enter the topic or main idea of your blog post...'
            }
          />
          {error && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-400">
              {error}
            </p>
          )}
        </div>
        <button
          type="submit"
          disabled={isGenerating || !description.trim()}
          className="w-full px-4 py-2 bg-purple-600 hover:bg-purple-700 disabled:bg-purple-400 disabled:cursor-not-allowed text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
        >
          <Wand2 className={`w-4 h-4 ${isGenerating ? 'animate-spin' : ''}`} />
          {isGenerating ? 'Generating...' : 'Generate Content'}
        </button>
      </form>
    </motion.div>
  );
};

export default AiFormGenerator;