import React from 'react';
import { AlertTriangle, WifiOff } from 'lucide-react';

interface ErrorMessageProps {
  message: string;
  onRetry?: () => void;
  isOfflineError?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, onRetry, isOfflineError }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] text-center px-4">
      {isOfflineError ? (
        <WifiOff className="w-12 h-12 text-yellow-500 mb-4" />
      ) : (
        <AlertTriangle className="w-12 h-12 text-red-500 mb-4" />
      )}
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
        {isOfflineError ? 'You are offline' : 'Something went wrong'}
      </h3>
      <p className="text-gray-600 dark:text-gray-400 mb-4">
        {message}
      </p>
      {onRetry && (
        <button
          onClick={onRetry}
          className={`px-4 py-2 ${
            isOfflineError
              ? 'bg-yellow-600 hover:bg-yellow-700'
              : 'bg-purple-600 hover:bg-purple-700'
          } text-white rounded-lg transition-colors`}
        >
          {isOfflineError ? 'Reconnect' : 'Try Again'}
        </button>
      )}
    </div>
  );
};

export default ErrorMessage;