import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export const enhanceText = async (text: string, type: string): Promise<string> => {
  if (!text.trim()) return text;

  try {
    const prompts = {
      title: "Enhance this title to make it more attractive and professional while maintaining its core meaning:",
      description: "Optimize this description to make it more compelling and professional while preserving its essence:",
      content: "Enrich this content to make it more engaging and professional while maintaining its main message:"
    };

    const prompt = `${prompts[type as keyof typeof prompts]} "${text}"`;

    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: "You are an expert in professional content optimization. Your task is to improve the text while maintaining its original meaning and intent."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
      max_tokens: 200
    });

    return completion.choices[0].message.content?.trim() || text;
  } catch (error) {
    console.error('Error enhancing text:', error);
    throw new Error('Failed to enhance text. Please try again.');
  }
};

export const generateProjectContent = async (description: string): Promise<any> => {
  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: "You are an expert in generating project content. Generate detailed project information based on the provided description. Return the response in JSON format."
        },
        {
          role: "user",
          content: `Generate detailed project content based on this description: "${description}". Include the following fields in JSON format:
            - title: A catchy title
            - slug: URL-friendly version of the title
            - description: A compelling short description
            - longDescription: A detailed project description
            - tags: Array of relevant technology tags
            - technologies: Array of main technologies used
            - features: Array of key features
            - challenges: Array of technical challenges faced
            - solutions: Array of solutions to challenges
            - links: Object with demo and github URLs (use placeholder URLs)`
        }
      ],
      temperature: 0.7,
      max_tokens: 1000,
      response_format: { type: "json_object" }
    });

    return JSON.parse(completion.choices[0].message.content || "{}");
  } catch (error) {
    console.error('Error generating project content:', error);
    throw new Error('Failed to generate project content. Please try again.');
  }
};

export const generateBlogContent = async (topic: string): Promise<any> => {
  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: "You are an expert in generating blog content. Generate a detailed blog post based on the provided topic. Return the response in JSON format."
        },
        {
          role: "user",
          content: `Generate a detailed blog post based on this topic: "${topic}". Include the following fields in JSON format:
            - title: An engaging title
            - slug: URL-friendly version of the title
            - excerpt: A captivating summary
            - content: Full article content in markdown format
            - category: Relevant category
            - tags: Array of relevant tags
            - author: Object with name, role, and avatar URL (use placeholder URL)`
        }
      ],
      temperature: 0.7,
      max_tokens: 1000,
      response_format: { type: "json_object" }
    });

    return JSON.parse(completion.choices[0].message.content || "{}");
  } catch (error) {
    console.error('Error generating blog content:', error);
    throw new Error('Failed to generate blog content. Please try again.');
  }
};